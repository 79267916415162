import Vue       from 'vue';
import VueRouter from 'vue-router';

import Home            from '../views/Home.vue';
import Software        from '../views/Software';
import Design          from '../views/Design';
import Confidentiality from '../views/Confidentiality';
import Terms           from '../views/Terms';
import Contact         from '../views/Contact';

Vue.use(VueRouter);

const routes = [
    {
        path     : '/',
        name     : 'Home',
        component: Home
    },
    {
        path     : '/dezvoltare-software',
        name     : 'Dezvoltare Software',
        component: Software
    },
    {
        path     : '/design-grafic',
        name     : 'Design Grafic',
        component: Design
    },
    {
        path     : '/confidentialitate',
        name     : 'Confidențialitate',
        component: Confidentiality
    },
    {
        path     : '/termeni',
        name     : 'Termeni',
        component: Terms
    },
    {
        path     : '/contact',
        name     : 'Contact',
        component: Contact
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.afterEach(() => {
    window.scrollTo(0, 0);
    // ...
});

export default router;
