export const technologies = [
    {
        image      : require('@/assets/img/software/laravel-intro.png'),
        title      : 'PHP/Laravel',
        description: 'Cel mai bun framework PHP, atât în prezent cât și în viitor.',
        link       : 'https://laravel.com/',
        action     : 'Ce este Laravel'
    },
    {
        image      : require('@/assets/img/software/vue-intro.png'),
        title      : 'Vue Js',
        description: 'Framework JavaScript progresiv ce se integrează perfect cu Laravel.',
        link       : 'https://vue.org/',
        action     : 'Ce este Vue Js'
    },
    {
        image      : require('@/assets/img/software/reactjs-intro.png'),
        title      : 'React',
        description: 'Librărie JavaScript modernă și avansată pentru aplicații complexe.',
        link       : 'https://reactjs.org/',
        action     : 'Ce este React'
    }
];

export const clients = [
    {
        logo: require('@/assets/img/clients/translating-for-humanity.png'),
        name: 'Translating For Humanity'
    },
    {
        logo: require('@/assets/img/clients/kirikids.png'),
        name: 'Kirikids'
    },
    {
        logo: require('@/assets/img/clients/becrime.png'),
        name: 'BeCrime'
    },
    {
        logo: require('@/assets/img/clients/wetranslate.png'),
        name: 'WeTranslate'
    },
    {
        logo: require('@/assets/img/clients/moviconstruct.png'),
        name: 'MoviConstruct'
    },
    {
        logo: require('@/assets/img/clients/pph.png'),
        name: 'Pizza PPH'
    },
    {
        logo: require('@/assets/img/clients/tva.png'),
        name: 'TVA srl'
    },
    {
        logo: require('@/assets/img/clients/ghostway.png'),
        name: 'Ghostway Solutions'
    },
    {
        logo: require('@/assets/img/clients/interlink.png'),
        name: 'Interlink'
    },
    {
        logo: require('@/assets/img/clients/brand-equation.png'),
        name: 'Brand Equation'
    },
    {
        logo: require('@/assets/img/clients/merchant.png'),
        name: 'Merchant Proxy'
    },
    {
        logo: require('@/assets/img/clients/quantum.png'),
        name: 'Quantum Newswire'
    },
    {
        logo: require('@/assets/img/clients/restapi.png'),
        name: 'Rest API'
    },
    {
        logo: require('@/assets/img/clients/review-link.png'),
        name: 'Review Link Generator'
    }
];
