<template>
    <div class="software">
        <Header class="pages-header">
            <div class="container-fluid">
                <div class="align-items-center">
                    <div class="col-xs-12">
                        <div class="intro text-center mt-4">
                            <h1>Dezvoltare Software</h1>
                            <p class="mb-2"></p>
                        </div>
                    </div>
                </div>
            </div>
        </Header>

        <div class="page">
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="technologies">
                        <div class="row">
                            <div class="col-xs-12 col-lg-4" v-for="(technology, index) in technologies" :key="index">
                                <div class="card">
                                    <div class="card-image">
                                        <img
                                            :src="technology.image"
                                            class="lazyload"
                                            :alt="technology.title"
                                        />
                                    </div>

                                    <div class="card-content">
                                        <h2>{{ technology.title }}</h2>
                                        <p>{{ technology.description }}</p>
                                    </div>

                                    <div class="card-actions">
                                        <a
                                            :href="technology.link"
                                            class="button action"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >{{ technology.action }} &#x276F;</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12">
                            <h1 class="mt-6">Cum Lucrăm?</h1>
                        </div>

                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col-xs-12 col-sm-6">
                                    <div class="service-step">
                                        <h1>01.</h1>
                                        <h2>Un pas înapoi</h2>
                                        <p>
                                            Poate sună ciudat dar înainte de a alerga trebuie să mergem
                                            și cu siguranță nu strică să primești o a doua opinie iar dacă punem totul
                                            la îndoială e pentru că ne dorim ca totul să fie perfect.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6"></div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col-xs-12 col-sm-6"></div>

                                <div class="col-xs-12 col-sm-6">
                                    <div class="service-step before-left">
                                        <h1>02.</h1>
                                        <h2>Privește în jur</h2>
                                        <p>
                                            Sunt foarte multe soluții existente și este un lucru bun să privim
                                            în jur pentru exemple, opinii și chiar competiție pentru a înțelege
                                            ce anume îi face pe ceilalți să aibă succes.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col-xs-12 col-sm-6">
                                    <div class="service-step before-right">
                                        <h1>03.</h1>
                                        <h2>Ia atitudine</h2>
                                        <p>
                                            Care este punctul tău forte? Hai să ne concentrăm mai întâi pe conținutul
                                            și funcționalitatea de bază și să începem de acolo. <br>
                                            Lucrurile mari se fac cu pași mici.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6"></div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col-xs-12 col-sm-6"></div>

                                <div class="col-xs-12 col-sm-6">
                                    <div class="service-step before-left">
                                        <h1>04.</h1>
                                        <h2>Pune pe hârtie</h2>
                                        <p>
                                            Va trebui să fim de acord cu ce anume trebuie să construim
                                            cu exactitate.
                                            Construim un model și îți prezentăm propunerea noastră urmând
                                            ca feedback-ul tău să ne ajute în perfecționarea acestuia.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col-xs-12 col-sm-6">
                                    <div class="service-step before-right">
                                        <h1>05.</h1>
                                        <h2>Construit cu sens</h2>
                                        <p>
                                            Având planul pus la punct, proiectăm interfața grafică
                                            cu un design unic, modern și funcțional iar în spate
                                            codăm folosind cele mai bune practici în dezvoltarea
                                            modernă cu PHP.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6"></div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col-xs-12 col-sm-6"></div>

                                <div class="col-xs-12 col-sm-6">
                                    <div class="service-step before-left">
                                        <h1>06.</h1>
                                        <h2>Testare riguroasă</h2>
                                        <p>
                                            Am ajuns în punctul în care totul lucrează la "unison"
                                            și unde trebuie să fim siguri că totul funcționează perfect,
                                            fără erori și limite de viteză indiferent de modul de accesare,
                                            desktop, tabletă sau mobil.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col-xs-12 col-sm-6">
                                    <div class="service-step before-right">
                                        <h1>07.</h1>
                                        <h2>Povestea continuă</h2>
                                        <p>
                                            Proiectele noaste cele mai de succes nu ajung niciodată
                                            la final și asta pentru că mentenanța și viitoarele îmbunătățiri
                                            suplimentare sunt esențiale pentru orice proiect ce își desfășoră
                                            activitatea în mediul online.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Contact/>

            <SideText/>
        </div>
    </div>
</template>

<script>
import Header         from '../components/Header';
import Contact        from '../components/Contact';
import SideText       from '../components/SideText';
import {technologies} from '../stubs/data';

export default {
    name      : 'Software',
    components: {SideText, Contact, Header},

    data () {
        return {
            technologies: []
        };
    },

    mounted () {
        this.technologies = technologies;
    }
};
</script>
