<template>
    <div>
        <div class="info-float left">
            <p>• ideea • plan • design • develop • test • deploy •</p>
        </div>

        <div class="info-float right">
            <p>• ideea • plan • design • develop • test • deploy •</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SideText'
};
</script>
