<template>
    <div class="contact">
        <Header class="pages-header">
            <div class="container-fluid">
                <div class="align-items-center">
                    <div class="col-xs-12">
                        <div class="intro text-center mt-4">
                            <h1>Contact</h1>
                            <p class="mb-2"></p>
                        </div>
                    </div>
                </div>
            </div>
        </Header>

        <div class="page">
            <div class="wrapper">
                <div class="container">
                    <div class="card dark no-shadow">
                        <div class="card-content">
                            <form method="POST" action="#">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="first-name">Nume</label>
                                            <input
                                                id="first-name"
                                                type="text"
                                                class="form-control"
                                                name="first_name"
                                            >
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="last-name">Prenume</label>
                                            <input
                                                id="last-name"
                                                type="text"
                                                class="form-control"
                                                name="last_name"
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="email">Email <span class="red-500">*</span></label>
                                            <input
                                                id="email"
                                                type="email"
                                                class="form-control"
                                                name="email"
                                            >
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="phone">Telefon</label>
                                            <input
                                                id="phone"
                                                type="text"
                                                class="form-control"
                                                name="phone"
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="project-type">Tip Proiect <span class="red-500">*</span></label>
                                            <select
                                                id="project-type"
                                                class="custom-select"
                                                name="project_type"
                                            >
                                                <option value="" selected disabled>-- Selectează Opțiune --</option>
                                                <option value="software-development">Dezvoltare Software</option>
                                                <option value="graphic-design">Design Grafic</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="budget">Buget Disponibil (Euro)</label>
                                            <input
                                                id="budget"
                                                type="number"
                                                class="form-control"
                                                name="budget"
                                            >
                                        </div>
                                    </div>

                                    <div class="col-xs-12">
                                        <div class="form-group">
                                            <label for="details">Detalii</label>
                                            <textarea
                                                id="details"
                                                class="form-control"
                                                name="details"
                                                type="text"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xs-12 mt-4">
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            name="terms"
                                            id="terms"
                                        >
                                        <label class="form-check-label" for="terms">Am citit
                                            <router-link to="termeni">Termenii și Condițiile</router-link>
                                        </label>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <button
                                        class="button small alternative"
                                        type="button"
                                    >
                                        Trimite Cerere
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
    name      : 'Contact',
    components: {Header}
};
</script>
