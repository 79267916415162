<template>
    <div class="design">
        <Header class="pages-header">
            <div class="container-fluid">
                <div class="align-items-center">
                    <div class="col-xs-12">
                        <div class="intro text-center mt-4">
                            <h1>Design grafic</h1>
                            <p class="mb-2"></p>
                        </div>
                    </div>
                </div>
            </div>
        </Header>

        <div class="page">
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xs-12">
                            <h1 class="mt-6">O paletă bogată de opțiuni</h1>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-lg-3">
                            <div class="graphic-item">
                                <img src="../assets/img/resources/logo-design.png" alt="Design Logo">
                                <h2>Design logo</h2>
                                <p>
                                    Crează un brand puternic cu un logo unic și memorabil care să vorbească în locul
                                    tău.
                                </p>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-lg-3">
                            <div class="graphic-item">
                                <img src="../assets/img/resources/retusare-logo.png" alt="Retușre Logo">
                                <h2>Retușare logo</h2>
                                <p>
                                    Îmbunătățește aspectul logo-ului tău completând-ul cu toate formatele de care ai
                                    nevoei
                                    pentru print și web.
                                </p>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-lg-3">
                            <div class="graphic-item">
                                <img src="../assets/img/resources/banner-web.png" alt="Bannere web">
                                <h2>Bannere web</h2>
                                <p>
                                    Atrage atenția clienților tăi în timp ce îmbunătățești rentabilitatea investiției cu
                                    un
                                    banner profesional.
                                </p>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-lg-3">
                            <div class="graphic-item">
                                <img
                                    src="../assets/img/resources/grafice-informationale.png"
                                    alt="Grafice Informaționale"
                                >
                                <h2>Grafice informaționale</h2>
                                <p>
                                    Sporește epxeriența conținutului digital prin furnizarea de informații utile cu un
                                    grafic
                                    informațional atractiv.
                                </p>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-lg-3">
                            <div class="graphic-item">
                                <img src="../assets/img/resources/carti-de-vizita.png" alt="Cărți de vizită">
                                <h2>Cărți de vizită</h2>
                                <p>
                                    Unice, cărțile de vizită sunt special concepute pentru a exprima esența
                                    afacerii tale.
                                </p>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-lg-3">
                            <div class="graphic-item">
                                <img src="../assets/img/resources/meniuri.png" alt="Meniuri">
                                <h2>Meniuri</h2>
                                <p>
                                    Meniuri expresive și încântătoare concepute pentru a stârni apetitul clientului tău.
                                </p>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-lg-3">
                            <div class="graphic-item">
                                <img src="../assets/img/resources/brosuri.png" alt="Broșuri">
                                <h2>Broșuri</h2>
                                <p>
                                    O reclamă de dimensiuni turistice, creată pentru a atrage atenția și interesul
                                    clienților
                                    tăi.
                                </p>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-lg-3">
                            <div class="graphic-item">
                                <img
                                    src="../assets/img/resources/servicii-design-grafic.png"
                                    alt="Servicii design grafic"
                                >
                                <h2>Orice ai nevoie</h2>
                                <p>
                                    Spune-ne de ce anume ai nevoie și noi vom face tot posibilul pentru tine.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="row">
                            <div class="col-xs-12">
                                <h1 class="mt-6">Cu ce te putem ajuta?</h1>
                            </div>

                            <div class="col-xs-12 col-md-6">
                                <h2>UX și Web Design</h2>
                                <p>
                                    Într-o lume în care o bună parte din navigarea pe internet se face folosind
                                    dispozitivele
                                    mobile,
                                    experiența utilizatorului este foarte importantă, dovadă fiind motoarele de căutare
                                    care
                                    premiază
                                    aplicațiile ale căror design este adaptabil oricărui dispozitiv. <br>
                                    Un design web optimizat îmbunătățește atât viteza cât și funcționalitatea site-ului
                                    tău
                                    transformând-ul
                                    într-un laborator digital interactiv ce servește publicul țintă.
                                </p>
                            </div>

                            <div class="col-xs-12 col-md-6">
                                <h2>De la online la offline</h2>
                                <p>
                                    Dezvoltarea de software web este principala noastră activitate, dar asta nu înseamnă
                                    că
                                    nu
                                    te putem ajuta
                                    și în eforturile tale offline. <br>
                                    În afară de web design și grafică digitală, oferim și soluții grafice pentru print
                                    indiferent că discutăm
                                    despre cărți de vizită concepute pentru a include noul logo, o broșură personalizată
                                    pentru
                                    promovarea afacerii,
                                    cupoane digitale imprimabile pentru a conduce clienții în locațiile magazinului tău
                                    etc.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <Contact/>
            </div>

            <SideText/>
        </div>
    </div>
</template>

<script>
import Header   from '@/components/Header.vue';
import Contact  from '@/components/Contact';
import SideText from '@/components/SideText';

export default {
    name      : 'Design',
    components: {SideText, Contact, Header}
};
</script>

<style scoped>

</style>
