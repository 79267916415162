import Vue from 'vue';
import App from './App.vue';

import './registerServiceWorker';
import './misc';

import router from './router';
import store  from './store';

import './assets/sass/app.scss';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
