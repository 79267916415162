<template>
    <div class="conf">
        <Header class="pages-header">
            <div class="container-fluid">
                <div class="align-items-center">
                    <div class="col-xs-12">
                        <div class="intro text-center mt-4">
                            <h1>Confidențialitate</h1>
                        </div>
                    </div>
                </div>
            </div>
        </Header>

        <div class="page">
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xs-12">
                            <h2>Politică de Confidențialitate</h2>
                            https://crobert.ro este gestionat de Robert-Cristian Chiribuc Persoană Fizică Autorizată și
                            se
                            angajează să vă protejeze intimitatea. <br>
                            Contactați-mă la <a href="mailto:contact@crobert.ro">contact@crobert.ro</a>
                            dacă aveți întrebări
                            sau
                            probleme cu privire la utilizarea datelor tale personale și vă voi ajuta cu plăcere.

                            <p>Prin utilizarea acestui site sau a serviciilor noastre, sunteți de acord cu procesarea
                               datelor dvs. personale
                               așa cum
                               este descris în această politică de confidențialitate.</p>

                            <p>Această politică de confidențialitate face parte din Termenii și condițiile site-ului;
                               prin
                               acceptarea
                               Termenilor și
                               condițiilor sunteți, de asemenea, de acord cu această politică. În caz de coliziune a
                               termenilor utilizați în
                               Termeni și
                               condiții și în Politica de confidențialitate, acestea din urmă prevalează.</p>
                            <h3>Cuprins</h3>
                            <ol>
                                <li>Definiții utilizate în această politică</li>
                                <li>Principiile de protecție a datelor pe care le urmăm</li>
                                <li>Ce drepturi ai cu privire la datele tale personale</li>
                                <li>Ce date personale colectăm despre tale</li>
                                <li>Cum folosim datele tale personale</li>
                                <li>Cine altcineva are acces la datele tale personale</li>
                                <li>Cum securizăm datele</li>
                                <li>Informații despre cookie-uri</li>
                                <li>Informații de contact</li>
                            </ol>
                            <h3 id="gdpr-definitions">Definiții</h3>
                            <strong>Date Personale</strong> – orice informație referitoare la o persoană fizică
                            identificată sau
                            identificabilă.
                            <br>
                            <strong>Procesare</strong> – orice operațiune sau set de operațiuni care se efectuează pe
                            date personale sau pe
                            seturi de date cu caracter personal.<br>
                            <strong>Persoana Vizată</strong> - o persoană fizică ale cărei date personale sunt
                            prelucrate.<br>
                            <strong>Copil</strong> - o persoană fizică sub 16 ani.<br>
                            <strong>Noi/noi</strong> (capitalizat sau nu) – https://crobert.ro<br>
                            <h3 id="gdpr-principles">Principii de Protecție a Datelor</h3>
                            Promit să respectăm următoarele principii privind protecția datelor:
                            <ul>
                                <li>Procesarea este legală, corectă, transparentă. Activitățile noastre de prelucrare au
                                    motive legale.
                                    Întotdeauna
                                    considerăm drepturile dvs. înainte de prelucrarea datelor personale. Vă vom oferi
                                    informații privind
                                    prelucrarea
                                    la cerere.
                                </li>
                                <li>Procesarea este limitată la scop. Activitățile noastre de procesare corespund
                                    scopului pentru care au
                                    fost
                                    colectate datele personale.
                                </li>
                                <li>Procesarea se face cu date minime. Reunim și procesăm numai cantitatea minimă de
                                    date personale necesare
                                    pentru
                                    orice scop.
                                </li>
                                <li>Procesarea este limitată cu o perioadă de timp. Nu vom stoca datele dvs. personale
                                    mai mult decât este
                                    necesar.
                                </li>
                                <li>Vom face tot posibilul pentru a asigura corectitudinea datelor.</li>
                                <li>Vom face tot posibilul pentru a asigura integritatea și confidențialitatea
                                    datelor.
                                </li>
                            </ul>
                            <h3 id="gdpr-rights">Drepturile Persoanei Vizate</h3>
                            Persoana vizată are următoarele drepturi:
                            <ol>
                                <li>Dreptul la informare - ceea ce înseamnă că trebuie să știți dacă datele dvs.
                                    personale sunt procesate;
                                    ce date
                                    sunt colectate, de unde sunt obținute și de ce și de cine sunt procesate.
                                </li>
                                <li>Dreptul de acces - ceea ce înseamnă că aveți dreptul de a accesa datele colectate de
                                    la / despre dvs.
                                    Aceasta
                                    include dreptul dvs. de a solicita și de a obține o copie a datelor dvs. personale
                                    culese.
                                </li>
                                <li>Dreptul la rectificare - ceea ce înseamnă că aveți dreptul să solicitați
                                    rectificarea sau ștergerea
                                    datelor dvs.
                                    personale care sunt inexacte sau incomplete.
                                </li>
                                <li>Dreptul de ștergere - ceea ce înseamnă că, în anumite circumstanțe, puteți solicita
                                    ca datele dvs.
                                    personale să
                                    fie șterse din înregistrările noastre.
                                </li>
                                <li>Dreptul de a restricționa procesarea - adică în cazul în care se aplică anumite
                                    condiții, aveți dreptul
                                    să
                                    restricționați procesarea datelor dvs. personale.
                                </li>
                                <li>Dreptul de a se opune prelucrării - adică în anumite cazuri aveți dreptul să vă
                                    opuneți procesării
                                    datelor dvs.
                                    personale, de exemplu în cazul marketingului direct.
                                </li>
                                <li>Dreptul de a se opune prelucrării automate - ceea ce înseamnă că aveți dreptul să
                                    faceți obiecții la
                                    prelucrarea
                                    automată, inclusiv la profilare; și să nu facă obiectul unei decizii bazate exclusiv
                                    pe prelucrarea
                                    automată.
                                    Puteți exercita acest drept ori de câte ori rezultatul obținut din urma profilării
                                    poate produce efecte
                                    juridice
                                    care te vor afecta sau te afectează în mod semnificativ.
                                </li>
                                <li>Dreptul la portabilitatea datelor - aveți dreptul să obțineți datele dvs. personale
                                    într-un format care
                                    poate fi
                                    citit de un calculator sau, dacă este posibil, ca un transfer direct de la un
                                    procesator la altul.
                                </li>
                                <li>Dreptul de a depune o plângere - în cazul în care refuzăm cererea dvs. în baza
                                    Drepturilor de Acces, vă
                                    vom
                                    oferi un motiv. Dacă nu sunteți mulțumit de modul în care a fost tratată solicitarea
                                    dvs., vă rugăm să
                                    ne
                                    contactați.
                                </li>
                                <li>Dreptul pentru ajutorul autorității de supraveghere - ceea ce înseamnă că aveți
                                    dreptul de a solicita
                                    ajutorul
                                    autorității de supraveghere și dreptul de a obține alte căi de atac, cum ar fi
                                    solicitarea de
                                    despăgubiri.
                                </li>
                                <li>Dreptul de a retrage consimțământul - aveți dreptul să retrageți orice consimțământ
                                    dat pentru
                                    procesarea
                                    datelor dvs. personale.
                                </li>
                            </ol>
                            <h3 id="gdpr-information">Date Colectate</h3>
                            <strong>Informații pe care ni le-ați furnizat</strong> <br>
                            Aceasta poate fi adresa dvs. de e-mail, numele, adresa de facturare, adresa de domiciliu
                            etc. - în principal
                            informațiile necesare pentru livrarea unui produs / serviciu sau pentru a vă îmbunătăți
                            experiența clienților cu
                            noi. Salvăm informațiile pe care ni le furnizați pentru a putea să comentați sau să
                            efectuați alte activități pe
                            site. Aceste informații includ, de exemplu, numele dvs. și adresa de e-mail.

                            <strong>Informațiile colectate automat despre dvs.</strong> <br>
                            Acestea includ informații care sunt stocate automat prin cookie-uri și alte instrumente de
                            sesiune. De exemplu,
                            informațiile despre adresa IP, istoricul de navigare(dacă există) etc. Aceste informații
                            sunt utilizate pentru a
                            vă îmbunătăți experiența din timpul navigării. Când utilizați serviciile noastre sau căutați
                            conținutul
                            site-ului nostru, activitățile dvs. pot fi înregistrate.

                            <strong>Informații de la partenerii noștri</strong> <br>
                            Strângem informații de la partenerii noștri de încredere, confirmând că au motive legale de
                            a împărtăși aceste
                            informații cu noi. Acestea sunt fie informații pe care le-ați furnizat direct, fie că s-au
                            adunat despre dvs.
                            din alte motive legale. Vedeți lista partenerilor noștri aici.

                            <strong>Informații disponibile publicului</strong> <br>
                            S-ar putea să strângem informații despre tine, care sunt disponibile publicului.
                            <h3 id="gdpr-processing">Cum folosim datele dvs. personale</h3>
                            Folosim datele dvs. personale pentru a:
                            <ul>
                                <li>asigura funcționarea serviciul nostru. Aceasta include, de exemplu, înregistrarea
                                    contului; oferindu-vă
                                    alte
                                    produse și servicii pe care le-ați solicitat; oferindu-vă la cererea dvs. elemente
                                    de promovare și
                                    comunicând cu
                                    dvs. în legătură cu aceste produse și servicii; comunicarea și interacțiunea cu
                                    dvs.; și a vă notifică
                                    despre
                                    modificările aduse oricăror servicii.
                                </li>
                                <li>sporiți experiența navigării;</li>
                                <li>îndeplini o obligație în temeiul legii sau al contractului;</li>
                            </ul>
                            Folosim datele dvs. personale pe motive legitime și / sau cu consimțământul dumneavoastră.

                            Pe baza încheierii unui contract sau a îndeplinirii obligațiilor contractuale, procesăm
                            datele dvs. personale în
                            următoarele scopuri:
                            <ul>
                                <li>pentru a vă identifica;</li>
                                <li>pentru a vă oferi un serviciu sau pentru a vă trimite / oferi un produs;</li>
                                <li>pentru a comunica cu dvs. fie pentru vânzări, fie pentru facturare;</li>
                            </ul>
                            Pe baza unui interes legitim, procesăm datele dvs. personale în următoarele scopuri:
                            <ul>
                                <li>pentru a vă trimite oferte personalizate * (de la noi și / sau partenerii noștri
                                    atent selectați);
                                </li>
                                <li>pentru a gestiona și analiza baza noastră de clienți;</li>
                                <li>pentru a îmbunătăți calitatea, varietatea și disponibilitatea produselor /
                                    serviciilor oferite /
                                    furnizate;
                                </li>
                                <li>pentru a efectua chestionare privind satisfacția clienților;</li>
                            </ul>
                            Atâta timp cât nu ne-ați informat altfel, considerăm că vă oferim produse / servicii care
                            sunt similare sau
                            identice cu comportamentul dvs. bazat pe istoria de navigare, pentru a fi interesul nostru
                            legitim.

                            Cu acordul dvs. procesăm datele dvs. personale în următoarele scopuri:
                            <ul>
                                <li>pentru a vă trimite buletine de știri și oferte de campanie (de la noi și / sau
                                    partenerii noștri atent
                                    selectați);
                                </li>
                                <li>pentru alte scopuri pentru care v-am cerut consimțământul;</li>
                            </ul>
                            Procesam datele dumneavoastra personale in scopul indeplinirii obligatiilor ce decurg din
                            lege si / sau
                            utilizarii datelor dvs. personale pentru optiunile prevazute de lege. Ne rezervăm dreptul de
                            a anonimiza datele
                            personale adunate și de a le folosi. Vom folosi datele în afara domeniului de aplicare al
                            prezentei Politici
                            numai atunci când este anonimă. Salvăm informațiile dvs. de facturare și alte informații
                            culese despre dvs. atât
                            timp cât este necesar în scopuri contabile sau alte obligații care decurg din lege, dar nu
                            mai mult de 5 ani.

                            Este posibil să ne procesăm datele personale pentru alte scopuri care nu sunt menționate
                            aici, dar sunt
                            compatibile cu scopul original pentru care au fost colectate datele. Pentru a face acest
                            lucru, ne vom asigura
                            că:
                            <ul>
                                <li>legătura dintre scopurile, contextul și natura datelor personale este adecvată
                                    pentru continuarea
                                    procesării;
                                </li>
                                <li>procesarea ulterioară nu vă va afecta interesele și</li>
                                <li>ar exista o garanție adecvată pentru procesare.</li>
                            </ul>
                            Vă vom informa despre orice procesare și scopuri ulterioare.
                            <h3 id="gdpr-sharing">Cine altcineva poate accesa datele dvs. personale</h3>
                            Nu împărtășim datele personale cu străinii. Datele personale despre dvs. sunt furnizate, în
                            unele cazuri,
                            partenerilor noștri de încredere pentru a vă oferi posibilitatea de a vă oferi serviciul sau
                            pentru a îmbunătăți
                            experiența clienților. Împărtășim datele dvs. cu:

                            <strong>Partenerii noștri de procesare:</strong>
                            <ul>
                                <li><strong><a href="https://netopia-payments.com/" target="_blank">Netopia Payments</a></strong>
                                </li>
                            </ul>
                            <strong>Partenerii noștri de afaceri:</strong>
                            <ul>
                                <li><strong>-</strong></li>
                            </ul>
                            <strong>Terțe părți conectate:</strong>
                            <ul>
                                <li><strong>-</strong></li>
                            </ul>
                            Lucrăm numai cu partenerii de procesare care sunt capabili să asigure un nivel adecvat de
                            protecție a datelor
                            dvs. personale. Dezvăluim datele dvs. personale terților sau funcționarilor publici atunci
                            când suntem obligați
                            din punct de vedere legal să facem acest lucru. Am putea dezvălui datele dvs. personale unor
                            terțe părți dacă
                            v-ați dat acordul sau dacă există alte motive legale.
                            <h3 id="gdpr-security">Cum Securizăm Datele</h3>
                            Facem tot posibilul pentru a păstra datele dvs. personale în siguranță. Utilizăm protocoale
                            sigure pentru
                            comunicare și transfer de date (cum ar fi HTTPS). Folosim anonimatul și pseudonimizarea
                            acolo unde este
                            potrivit. Monitorizăm sistemele noastre pentru posibile vulnerabilități și atacuri.

                            Chiar dacă încercăm tot ce putem, nu putem garanta securitatea informațiilor. Cu toate
                            acestea, promitem să
                            notificăm autoritățile competente privind încălcările datelor. De asemenea, vă vom anunța
                            dacă există o
                            amenințare la adresa drepturilor sau intereselor dvs. Vom face tot ceea ce putem în mod
                            rezonabil pentru a
                            preveni încălcarea securității și pentru a asista autoritățile în cazul în care vor apărea
                            încălcări.
                            <h3>Copiii</h3>
                            Nu intenționăm să colectăm sau să colectăm cu bună știință informații de la copii. Nu vizăm
                            copiii cu serviciile
                            noastre.
                            <h3 id="gdpr-cookies">Cookie-uri și alte tehnologii pe care le folosim</h3>
                            Utilizăm cookie-uri și / sau tehnologii similare pentru a analiza comportamentul clienților,
                            pentru a administra
                            site-ul, a urmări mișcările utilizatorilor și pentru a colecta informații despre
                            utilizatori. Acest lucru se
                            face pentru a vă personaliza și îmbunătăți experiența cu noi.

                            Un cookie este un fișier text mic stocat pe computer. Cookie-urile stochează informațiile
                            care sunt folosite
                            pentru a ajuta site-urile să funcționeze. Numai noi putem accesa cookie-urile create de
                            site-ul nostru. Puteți
                            controla cookie-urile la nivel de browser. Alegerea dezactivării modulelor cookie poate
                            împiedica utilizarea
                            anumitor funcții.

                            <strong>Utilizăm cookie-urile în următoarele scopuri:</strong>
                            <ul>
                                <li>Cookie-uri necesare - aceste module cookie sunt necesare pentru a putea utiliza
                                    câteva funcții
                                    importante pe
                                    site-ul nostru, cum ar fi conectarea. Aceste cookie-uri nu colectează informații
                                    personale.
                                </li>
                                <li>Cookie-uri funcționale - aceste cookie-uri oferă funcționalitate care face ca
                                    utilizarea serviciului
                                    nostru să
                                    fie mai convenabilă și face posibila oferirea de funcții mai personalizate. De
                                    exemplu, aceștia ar putea
                                    să-și
                                    amintească numele și adresa de e-mail în formularele de comentarii, astfel încât nu
                                    trebuie să
                                    reintroduceți
                                    această informație data viitoare când comentați.
                                </li>
                                <li>Cookie-urile de analiză - aceste module cookie sunt utilizate pentru a urmări
                                    utilizarea și performanța
                                    site-ului și a serviciilor noastre.
                                </li>
                                <li>Cookie-uri de publicitate - aceste cookie-uri sunt folosite pentru a difuza anunțuri
                                    care sunt relevante
                                    pentru
                                    dvs. și pentru interesele dvs. În plus, acestea sunt utilizate pentru a limita
                                    numărul de afișări pe
                                    care le
                                    vedeți. Acestea sunt, de obicei, plasate pe site-ul web prin rețele de publicitate
                                    cu permisiunea
                                    operatorului
                                    de site. Aceste cookie-uri iti aduc aminte ca ai vizitat un site web si aceste
                                    informatii sunt
                                    impartasite altor
                                    organizatii, cum ar fi agentii de publicitate. Adesea, cookie-urile de vizare sau de
                                    publicitate vor fi
                                    legate
                                    de funcționalitatea site-ului furnizată de cealaltă organizație.
                                </li>
                            </ul>
                            Puteți elimina cookie-urile stocate în computer prin setările browserului. În mod
                            alternativ, puteți controla
                            anumite cookie-uri terță parte utilizând o platformă de îmbunătățire a confidențialității,
                            cum ar fi as
                            <a href="http://optout.aboutads.info/#!/" target="_blank" rel="noopener">optout.aboutads.info</a>
                            sau <a
                            href="http://www.youronlinechoices.com/" target="_blank" rel="noopener">youronlinechoices.com</a>.
                            <br>
                            Pentru mai multe informații despre modulele cookie, vizitați
                            <a href="http://www.allaboutcookies.org" target="_blank"
                               rel="noopener">allaboutcookies.org</a>.

                            Utilizăm Google Analytics pentru a măsura traficul pe site-ul nostru. Google are propria
                            politică de
                            confidențialitate pe care o puteți revizui
                            <a href="https://support.google.com/analytics/answer/6004245" target="_blank"
                               rel="noopener">aici</a>. <br>
                            Dacă doriți să renunțați la urmărirea de către Google Analytics, accesați pagina de
                            <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener">dezactivare
                                                                                                              Google
                                                                                                              Analytics</a>.
                            <h3 id="gdpr-contact">Informații de Contact</h3>
                            <strong id="gdpr-dpa">Autoritatea de Supraveghere</strong> <br>
                            Website: <a href="http://www.dataprotection.ro/" target="_blank"
                                        rel="noopener">http://www.dataprotection.ro/</a> <br>
                            Email: <a href="mailto:anspdcp@dataprotection.ro">anspdcp@dataprotection.ro</a> <br>
                            Telefon: +40 21 252 5599 <br>
                            <h3 id="gdpr-changes">Modificări la această politică de confidențialitate</h3>
                            Ne rezervăm dreptul de a modifica această politică de confidențialitate. <br>
                            Ultima modificare a fost făcută 06 Ianuarie 2022.
                        </div>
                    </div>
                </div>
            </div>

            <SideText/>
        </div>
    </div>
</template>

<script>
import Header   from '../components/Header';
import SideText from '../components/SideText';

export default {
    name      : 'Confidentiality',
    components: {SideText, Header}
};
</script>
