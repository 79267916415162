<template>
    <div class="home">
        <Header>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xs-12 col-lg-6">
                        <div class="intro">
                            <h1>
                                Cod de calitate
                                <br>
                                <span class="text-bold">pentru ideile tale</span>
                            </h1>
                            <p class="mb-2">
                                Ajutăm companiile să-și transforme ideile în aplicații, produse și servicii
                                uimitoare.
                            </p>

                            <router-link to="contact" class="button alternative mt-4">
                                Solicită o ofertă
                            </router-link>
                        </div>
                    </div>

                    <div class="col-xs-12 col-lg-6">
                        <div class="vector">
                            <img src="../assets/img/resources/header-intro.png" class="lazyload"
                                 alt="CRobert"/>
                        </div>
                    </div>
                </div>
            </div>
        </Header>

        <section class="services">
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row middle-xs">
                        <div class="col-xs-12 col-sm-6">
                            <div class="image-service">
                                <img src="../assets/img/resources/web-dev-service.png" class="lazyload"
                                     alt="Web Development"/>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <h1>01.</h1>
                            <h2>Dezvoltare Software</h2>
                            <p>
                                Pornind cu Laravel și continuând cu ReactJs sau VueJs, oferim viață proiectelor
                                inovatoare și provocatoare transformând ideile și viziunile tale în realitate prin
                                conectarea obiectivelor afacerii cu nevoile utilizatorilor obținând astfel rezultate de
                                calitate.
                            </p>
                        </div>
                    </div>

                    <div class="row middle-xs">
                        <div class="col-xs-12 col-sm-6 first-sm">
                            <h1>02.</h1>
                            <h2>Design Web</h2>
                            <p>
                                Luăm foarte în serios aspectul unei aplicații și facem tot posibilul să oferim
                                utilizatorilor un conținut vizual atractiv și o interactivitate lină prin crearea unui
                                design autentic, original și calitativ.
                            </p>
                        </div>
                        <div class="col-xs-12 col-sm-6 first-xs">
                            <div class="image-service">
                                <img src="../assets/img/resources/web-design-service.png" class="lazyload"
                                     alt="Web Design"/>
                            </div>
                        </div>
                    </div>

                    <div class="row middle-xs">
                        <div class="col-xs-12 col-sm-6">
                            <div class="image-service">
                                <img src="../assets/img/resources/web-graph-service.png" class="lazyload"
                                     alt="Design Grafic"/>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <h1>03.</h1>
                            <h2>Design Digital și Print</h2>
                            <p>
                                Fie că vorbim despre o întreagă identitate (branding) sau doar despre o mică parte din
                                ceea ce contribuie la prezența vizual estetică a afacerii sau a produsului tău în mediul
                                online, suntem pregătiți oricând pentru orice fel de provocare.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <SideText/>
        </section>

        <section class="roles">
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xs-12 col-md-8 col-md-offset-2 title main-title">
                            <h2>Îmbrățișăm Colaborările</h2>
                            <p>
                                Îndeplinim trei roluri principale pentru clienții noștri și pentru că fiecare proiect
                                este diferit încurajăm amestecarea și potrivirea celor mai bune soluții pentru a obține
                                cele mai bune rezultate.
                            </p>
                        </div>
                    </div>

                    <div class="row align-items-center">
                        <div class="col-xs-12 col-md-6">
                            <div class="row">
                                <div class="col-xs-12 title">
                                    <h1>01.</h1>
                                    <h2>Partener</h2>
                                    <p>
                                        Colaborăm îndeaproape cu echipa ta pentru a vă ajuta să furnizați clienților
                                        soluții tehnice de cea mai bună calitate
                                    </p>
                                </div>

                                <div class="col-xs-12 title">
                                    <h1>02.</h1>
                                    <h2>Echipă Incorporată</h2>
                                    <p>
                                        Dacă ai o idee grozavă și fonduri pentru a o susține dar angajarea de
                                        programatori este dificilă și costisitoare.
                                    </p>
                                </div>

                                <div class="col-xs-12 title">
                                    <h1>03.</h1>
                                    <h2>Consultanță</h2>
                                    <p>
                                        Lucrăm împreună cu echipa ta pentru a vă perfecționa procesele de dezvoltare
                                        analizând codul și modul de lucru.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-md-6 self-center">
                            <img src="../assets/img/resources/teamwork.png" class="lazyload"
                                 alt="Roluri Colaborare"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="technologies">
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xs-12 col-md-8 col-md-offset-2 title main-title">
                            <h2>Tehnologii Folosite</h2>
                            <p>
                                Cu un depozit plin de unelte profesionale ne mândrim să le folosim pe cele mai
                                "ascuțite" dintre acestea căutând în permanență menținerea unui echilibru perfect.
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-lg-4" v-for="(technology, index) in technologies" :key="index">
                            <div class="card">
                                <div class="card-image">
                                    <img
                                        :src="technology.image"
                                        class="lazyload"
                                        :alt="technology.title"
                                    />
                                </div>

                                <div class="card-content">
                                    <h2>{{ technology.title }}</h2>
                                    <p>{{ technology.description }}</p>
                                </div>

                                <div class="card-actions">
                                    <a
                                        :href="technology.link"
                                        class="button action"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >{{ technology.action }} &#x276F;</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="clients">
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xs-12 col-md-8 col-md-offset-2 title main-title">
                            <h2>Clienții Noștri</h2>
                            <p>
                                Am avut plăcerea de a lucra cu o mulțime de clienți minunați, din toate colțurile lumii.
                                <br/> Și tu poți fi unul dintre ei.
                            </p>
                        </div>
                    </div>

                    <div class="logos">
                        <div class="client-logo" v-for="(client, index) in clients" :key="index">
                            <img
                                :src="client.logo"
                                class="lazyload"
                                :alt="client.name"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Contact/>
    </div>
</template>

<script>
import {clients, technologies} from '@/stubs/data';
import Header                  from '../components/Header';
import Contact                 from '../components/Contact';
import SideText                from '../components/SideText';

export default {
    name      : 'Home',
    components: {SideText, Contact, Header},
    data () {
        return {
            technologies: [],
            clients     : []
        };
    },

    mounted () {
        this.technologies = technologies;
        this.clients      = clients;
    }
};
</script>
