<template>
    <div class="conf">
        <Header class="pages-header">
            <div class="container-fluid">
                <div class="align-items-center">
                    <div class="col-xs-12">
                        <div class="intro text-center mt-4">
                            <h1>Termeni</h1>
                        </div>
                    </div>
                </div>
            </div>
        </Header>

        <div class="page">
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xs-12">
                            <h2><strong>Termeni și Condiții GDPR</strong></h2>

                            <p> Condiții Generale pentru utilizarea site-ului https://crobert.ro</p>

                            <h3><strong>Acceptarea Condițiilor</strong></h3>

                            <p>Prin accesarea acestui site web și/sau a oricărei pagini a acestuia sunteți de acord cu
                               aceste condiții de utilizare. Dacă nu sunteți de acord cu acești termeni și condiții de
                               utilizare, nu accesați acest site.</p>

                            <h3><strong>Descrierea Serviciilor</strong></h3>

                            <p> Siteul nostru vă pune la dispoziție informațiile din acest site web în scop informativ
                                general și nu garantează de exactitatea lor la un moment dat, deși se va încerca pe cât
                                posibil ca la publicarea lor pe site toate informațiile să fie exacte.</p>

                            <h3><strong>Introducere</strong></h3>

                            <p>Prezentele Condiţii Generale definesc condiţiile de utilizare a site-ului
                               https://crobert.ro de către potenţialii vizitatori sau clienţi. <br>Accesând şi navigând
                               pe acest site, acceptaţi termenii de utilizare descrişi în continuare.</p>

                            <h3><strong>Drepturile Asupra Conținutului Site-ului</strong></h3>

                            <p>CHIRIBUC ROBERT-CRISTIAN PERSOANĂ FIZICĂ AUTORIZATĂ, nu
                               va transfera titlul de proprietate asupra aplicaţiilor software.</p>

                            <p>https://crobert.ro deţine drepturi complete şi depline asupra titlului de proprietate şi
                               prin
                               aceasta toate drepturile de autor
                               şi
                               cele brevetate. <br>Nu aveţi dreptul să redistribuiţi, vindeţi, decompilaţi, dezasamblaţi
                               aplicaţia software într-o formă perceptibilă de către oameni.</p>

                            <p>Toate informaţiile, produsele sau aplicaţiile conţinute în acest site sunt proprietatea
                               https://crobert.ro, care îşi rezervă
                               dreptul de a modifica conţinutul şi/sau structura site-ului în orice moment şi fară nici
                               o informare prealabilă.</p>

                            <p>Întregul conţinut al site-ului https://crobert.ro este protejat prin Legea drepturilor de
                               autor, toate drepturile
                               fiind rezervate. <br>Toate drepturile referitoare la pagini, conţinutul şi prezentarea
                               site-ului sunt deţinute de
                               https://crobert.ro. <br><br>Este interzisă copierea, modificarea, afişarea, distribuirea,
                               transmiterea, publicarea,
                               comercializarea, licenţierea, crearea unor materiale derivate sau utilizarea conţinutului
                               site-ului în orice scop
                               fară confirmarea scrisă din partea https://crobert.ro.</p>

                            <p>Accesul si utilizarea paginii https://crobert.ro sunt gratuite si au scopul de a veni in
                               ajutorul utilizatorilor pentru a găsi informațiile necesare în cel mai ușor si rapid mod
                               posibil, conform cerințelor fiecăruia.</p>

                            <p>Informațiile prezente pe https://crobert.ro sunt de interes general și sunt puse la
                               dispoziția utilizatorilor în mod gratuit. <br><br>Prin termenul „utilizator” al acestei
                               pagini se înțelege orice persoana fizică sau juridică care va accesa pagina. Puteți copia
                               și tipări conținutul paginii https://crobert.ro pentru folosința dumneavoastră personală,
                               fără intenții comerciale. <br><br>În orice alte situații, conținutul
                               https://crobert.ro nu
                               poate fi reprodus, modificat sau exploatat fără consimțământul explicit al
                               reprezentanților https://crobert.ro.</p>

                            <h3><strong>Neangajarea Răspunderii</strong></h3>

                            <p>Conţinutul informaţiilor se referă la descrierea într-un anumit grad de detaliere a
                               activităţilor, produselor şi
                               serviciilor https://crobert.ro. <br><br>https://crobert.ro nu va acorda nici o garanţie
                               referitoare la:</p>

                            <ul>
                                <li>evitarea utilizării anevoioase sau întreruperii în utilizare a site-ului;</li>
                                <li>neafectarea în sens negativ a altor sisteme prin utilizarea site-ului;</li>
                                <li>neafectarea în sens negativ a altor sisteme prin utilizarea site-ului;</li>
                                <li>inexistenţa pe site-ul pus la dispoziţie a viruşilor sau alte componente care ar
                                    putea dăuna utilizatorilor.
                                </li>
                            </ul>

                            <p>Astfel, https://crobert.ro nu poate fi responsabilă pentru nici un fel de daune directe
                               sau
                               indirecte produse prin utilizarea
                               site-ului său.<br><br>Toate informațiile prezentate pe site cu privire la produsele
                               https://crobert.ro, prețuri, informații,
                               campanii și promoții de marketing, aspecte tehnice, sunt prezentate cu titlu informativ.
                                <br><br>Nimic din
                               conținutul site-ului https://crobert.ro nu poate constitui o ofertă fermă de a contracta
                               și nu poate angaja
                               răspunderea https://crobert.ro în lipsa unor acorduri ulterioare.</p>

                            <p>Conținutul paginii web https://crobert.ro, a informațiilor și a altor materiale
                               prezentate
                               nu reprezintă o consultație juridică în sensul Legii nr. 51/1995.</p>

                            <p>GDPR este un set de reguli bazat pe principii complexe, care este deschis la interpretare
                               și conține, de asemenea, numeroase domenii în care statele membre sunt invitate să
                               intervină. <br><br>În prezent există foarte puține îndrumări cu privire la implementarea
                               GDPR și este posibil ca Autoritățile de Supraveghere să aibă puncte de vedere diferite
                               față de cele exprimate în acest șablon. <br><br>În ciuda eforturilor noastre de a oferi
                               cele mai bune servicii, nu putem anticipa cu exactitate modul în care Autoritatea de
                               Supraveghere sau instanțele de judecată vor interpreta Regulamentul.</p>

                            <p>https://crobert.ro are intenția de îndruma și de a ajuta în implementarea cât mai corectă
                               a <a href="http://www.dataprotection.ro/servlet/ViewDocument?id=1262">
                                    Regulamentului (UE) 2016/679 privind protecția persoanelor fizice în ceea ce
                                    privește prelucrarea datelor cu caracter personal și privind libera circulație a
                                    acestor date și de abrogare a Directivei 95/46/CE</a>.</p>

                            <p>https://crobert.ro, https://crobert.ro, reprezentanții și consultanții lor nu fac nicio
                               declarație,
                               promisiune sau garanție cu
                               privire la exactitatea, exhaustivitatea sau caracterul adecvat al prezentului conținut,
                               al îndrumărilor și a consultanței, nu își asumă nicio obligație de rezultat sau diligență
                               față de nicio persoană, exclud în mod expres și neagă răspunderea pentru orice cost,
                               pierdere sau daunele suferite ca urmare a utilizării conținutului.</p>

                            <h3><strong>Obiectivul Conținutului Site-ului</strong></h3>

                            <p> Obiectivul conţinutului site-ului este de a prezenta și transmite informaţii privind
                                domeniul IT (dezvoltare software, servere și alte servicii sau produse relevante,
                                tutoriale și recomandări).</p>

                            <p>https://crobert.ro nu poate garanta că prezentele pagini nu conţin erori, şi asigură că
                               va depune
                               toate diligenţele pentru
                               realizarea unei informări corecte şi remedierea eventualelor erori.</p>

                            <p>Orice persoană care doreşte să-şi procure unul din serviciile sau produsele prezentate în
                               site este rugată să
                               contacteze https://crobert.ro prin unul din mijloacele afişate în pagina de Contact a
                               site-ului,
                               pentru a se informa atât
                               asupra
                               disponibilităţii serviciului sau produsului în cauză cât şi asupra condiţiilor
                               contractuale, tarifelor şi informaţiilor tehnice sau de altă natură.</p>

                            <h3><strong>Informații Personale</strong></h3>

                            <p>Când prin intermediul prezentului site vă sunt solicitate informaţii, aceasta are ca scop
                               identificarea
                               dumneavoastră sau posibilitatea de a vă contacta. Acest lucru este cu atât mai necesar în
                               cazul folosirii
                               aplicaţiilor disponibile pe site.<br><br>Natura informaţiilor solicitate se referă în
                               special la date personale
                               (nume, adresă, numere de telefon), adresă de e-mail, modul în care sunt utilizate sau
                               intenţionează să fie
                               utilizate produsele şi/sau serviciile https://crobert.ro, dar poate include şi alte
                               informaţii
                               aflate în strânsă legătură cu
                               utilizarea serviciilor şi/sau produselor solicitate.</p>

                            <p>Cu scopul de a răspunde mai bine nevoilor şi întrebărilor utilizatorilor site-ului,
                               informaţiile solicitate prin intermediul acestui site vor face obiectul unei stocări şi
                               prelucrări electronice.</p>

                            <h3><strong>Comunicarea Cu Vizitatorii Site-ului</strong></h3>

                            <p> Metodele prin care vizitatorii site-ului inițiază contactul cu noi sunt: adresă de
                                e-mail, număr de telefon, formularele prezente pe site.</p>

                            <p>Această contactare are loc pentru a cere informații despre produsele noastre, pentru a ne
                               solicita oferte de preț, pentru a ne consulta părerea pe diferite subiecte, pentru a
                               beneficia de serviciile noastre, pentru a facilita procesul de plată.</p>

                            <p>Inițierea contactului de către d-voastră îl considerăm / reprezintă consimțământul
                               d-voastră pentru a vi se răspunde înapoi, a vi se furniza informațiile, a primi detalii
                               de plata sau orice informație solicitată.</p>

                            <p>Ulterior desfășurării comunicării inițiale, https://crobert.ro își stabilește ca interes
                               legitim
                               posibilitatea de a vă
                               transmite
                               și comunica informații ulterioare, relevante solicitării d-voastră, fără a avea scop de
                               marketing sau caracter de newsletter. <br><br>Aceste informații pot apărea în urma
                               îmbunătățirii produselor noastre, lansarea de produse noi care vin în ajutorul d-voastră
                               sau aspecte care au legătură cu tematica discutată. Comunicarea se va realiza prin
                               intermediul poștei electronice sau MailChimp (https://mailchimp.com/gdpr/).</p>

                            <p>În acest sens https://crobert.ro și-a stabilit un termen rezonabil de prelucrare
                               ulterioară de 18
                               luni de la data inițierii
                               comunicării.</p>

                            <h3><strong>Legătura Cu Alte Site-uri</strong></h3>

                            <p>Prezentul site poate conţine legături sau trimiteri către alte site-uri considerate de
                               https://crobert.ro utile în legătură cu
                               conţinutul site-ului său şi care nu se află sub controlul sau îndrumarea sa. <br><br>În
                               cazul utilizării acestor legături sau trimiteri se vor aplica condiţiile generale de
                               utilizare corespunzătoare acelor site-uri.</p>

                            <p>https://crobert.ro nu poate garanta/controla actualitatea/exactitatea informaţiilor
                               prezente pe
                               site-urile unor terţi, la care
                               se
                               face trimitere de pe site-ul său.</p>


                            <h3><strong>Modalități de Plată</strong></h3>

                            <p>Pentru serviciile oferite de către https://crobert.ro, plata facturilor aferente
                               serviciilor se face
                               folosint
                               următoarele instrumente de plată:</p>

                            <strong>Transfer Bancar</strong> <br>
                            Plata se poate face prin virament bancar pe baza facturii proforme conform condițiilor
                            contractuale agreate de
                            către prestator și client.
                            <p>Datele necesare pentru efectuarea plății prin transfer bancar sunt:</p>
                            <ul>
                                <li>CHIRIBUC ROBERT-CRISTIAN PERSOANĂ FIZICĂ AUTORIZATĂ</li>
                                <li>CUI: 45377753</li>
                                <li>Nr. Reg. Com.: F40/3429/2021</li>
                                <li>Sediul social: Str. Mihail Sebastian, nr. 28, Cam. 3, Sector 5,
                                    București, 050784</li>
                                <li>ING Bank: <strong>RO84INGB0000999912172608</strong></li>
                            </ul>

                            <strong>Online prin Card Bancar</strong> <br>
                            Pentru plățile cu carduri de credit/debit emise sub sigla Visa și MasterCard (Visa/Visa
                            Electron și
                            MasterCard/Maestro) este folosit sistemul de plată 3D secure.

                            <p>Acest sistem asigură tranzacțiilor online, același nivel de securitate ca și cele
                               realizate la bancomat sau în
                               mediul fizic, la comerciant. 3D Secure este un sistem de autentificare a identității
                               posesorilor de carduri în
                               mediul electronic. <br> Procesul de autentificare a posesorului de card se face pe baza
                               codului de securitate
                               cunoscut numai de posesor și se desfășoară numai pe serverele Visa sau MasterCard, după
                               caz.</p>
                            <p>Sistemul 3D Secure permite facturarea de cumpărături și plăți on-line cu orice card emis
                               sub licența Visa sau
                               MasterCard, inclusiv cele de debit și cele electronice Maestro și Visa Electron. Sunt
                               acceptate și cardurile
                               virtuale emise de aceste organizații.</p>
                            <strong>Plățile prin card online sunt procesate de către <a href="https://netopia-payments.com/"
                                                                                        target="_blank">Netopia Payments</a>
                                    prin intermediul unei platforme securizate
                                    .</strong>
                            <p>Sistemul de procesare a plăților online Netopia Payments ia măsurile de securitate
                               necesare
                               pentru a proteja utilizatorii
                               împotriva accesului neautorizat sau pentru modificarea neautorizată, distrugerea sau
                               divulgarea datelor stocate
                               prin serviciul Netopia Payments.
                            </p>
                            <p>În momentul inițierii plății online prin accesarea link-ului de plată primit prin email,
                               folosind sistemul de
                               plată cu cardul prin Netopia Payments, suma de bani aferentă va fi indisponibilizată în
                               contul
                               dvs. Transferul efectiv,
                               către CHIRIBUC ROBERT-CRISTIAN PERSOANĂ FIZICĂ AUTORIZATĂ se va face după confirmarea
                               plății efective.</p>

                            <h3><strong>Avansul</strong></h3>
                            <p>În funcție de tipul serviciilor contractate se poate percepe un avans dar nu mai mult
                            de 20% din prețul total al serviciilor și reprezintă o garanție ce nu este supusă
                               returnării dacă de la momentul semnării contractului și plății acestui avans au trecut
                               mai mult de 14 zile lucrătoare.</p>

                            <h3><strong>Livrarea Serviciilor</strong></h3>
                            <p>Pentru serviciile contractate, livrarea
                               materialelor, codului sursă și a oricăror altor elemente grafice se va face folosind
                               una dintre metodele de mai jos sau prin orice altă metodă așa cum este ea prezentată
                               în contractul de prestări servicii și pentru care, clientul și-a dat acordul prin
                               semnarea acestuia:</p>
                            <ul>
                                <li>arhivă electronică prin email sau link cloud</li>
                                <li>instalate sau stocate pe server-ul clientului</li>
                            </ul>
                            <p>În urma recepționării serviciilor și eventual testării acestora, se va semna
                               un document de predare-primire prin care clientul se declară mulțumit de serviciile
                               primite și că acestea îndeplinesc condițiile impuse în contractul încheiat și/sau
                               anexele sale și se va emite factura aferentă acestor servicii.
                               </p>

                            <h3><strong>Politică Retur</strong></h3>
                            <p>Plățile efectuate ca urmare a serviciilor prestate de către CHIRIBUC ROBERT-CRISTIAN
                               PERSOANĂ FIZICĂ AUTORIZATĂ nu se pot returna dacă în urma livrării serviciilor
                               conform contractului încheiat între client și prestator s-a semnat procesul verbal de
                               predare-primire.</p>
                            <p>Pentru plățile cu titlu de avans, clientul are opțiunea de a solicita în scris în
                               termen de 14 zile lucrătoare de la data semnării contractului de presări servicii,
                               dorința de retragerea din contract precum și returnarea oricăror sume ce au fost
                               percepute în avans, urmând ca prestatorul să returneze în termen de 5 zile lucrătoare
                            sumele percepute în avans prin transfer bancar dacă nu este specificat altfel în
                               contractul de prestări servicii.</p>

                            <p>Orice dispută privind returnarea fondurilor se soluționează conform legii și în baza
                               condițiilor contractuale agreate
                               de către client, în urma contractării serviciilor solicitate și oferite de către CHIRIBUC
                               ROBERT-CRISTIAN PERSOANĂ
                               FIZICĂ AUTORIZATĂ.</p>

                            <h3><strong>Dreptul de retragere</strong></h3>
                            <p>Conform O.U.G. nr. 34 din 14.06.2014 art. 9, alin. (1) și alin. (2) lit. a, clientul are
                               dreptul de a solicita rezilierea contractului și de a se retrage din acesta în termen de
                               14 zile de la data încheierii contractului de prestări servicii încheiat între Chiribuc
                               Robert-Cristian Persoană Fizică Autorizată și acesta.</p>

                            <h3><strong>Exceptări de la dreptul de retragere</strong></h3>
                            <p>Conform O.U.G. nr. 34 din 14.06.2014 art. 16, lit. a și lit. m.</p>


                            <h3><strong>Informațiile Oferite Prin Intermediul Site-ului</strong></h3>

                            <p>Orice persoană care vizitează site-ul https://crobert.ro şi care oferă date sau
                               informaţii
                               cu caracter personal prin intermediul acestui site îşi manifestă acordul în mod expres şi
                               neechivoc pentru următoarele: </p>

                            <ul>
                                <li>prelucrarea acestor date şi informaţii personale de către https://crobert.ro în
                                    vederea
                                    efectuării de studii de piaţă;
                                </li>
                                <li>transmiterii de materiale promoţionale specifice operaţiunilor de marketing direct;
                                    soluţionarea de
                                    către https://crobert.ro a cererilor, întrebărilor şi reclamaţiilor adresate (a se
                                    vedea pagina
                                    Contact din site);
                                </li>
                                <li>alte activităţi întreprinse de https://crobert.ro şi permise de lege, ce nu fac
                                    obiectul unei
                                    aprobări din partea
                                    destinatarului.
                                </li>
                            </ul>

                            <p>https://crobert.ro va păstra confidenţialitatea acestor informaţii.</p>

                            <p>Utilizarea în continuare a acestui site constituie acordul expres şi neechivoc al
                               dumneavoastră în conformitate cu directivele GDPR pentru protecţia persoanelor cu privire
                               la prelucrarea datelor cu caracter personal şi libera circulaţie a acestor date.</p>

                            <p>Pentru orice nelămurire în legătură cu exercitarea drepturilor dumneavoastră referitoare
                               la utilizarea site-ului şi la protecţia utilizării vă rugăm să ne contactaţi prin
                               intermediul secţiunii Contact din site.</p>
                        </div>
                    </div>
                </div>
            </div>

            <SideText/>
        </div>
    </div>
</template>

<script>
import Header   from '../components/Header';
import SideText from '../components/SideText';

export default {
    name      : 'Terms',
    components: {Header, SideText}
};
</script>
