<template>
    <div class="main">
        <div id="app">
            <!--<div id="nav">-->
            <!--    <router-link to="/">Home</router-link>-->
            <!--    |-->
            <!--    <router-link to="/about">About</router-link>-->
            <!--</div>-->

            <router-view/>

            <Footer/>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-xs-12 mt-2 payments">
                    <a href="https://netopia-payments.com/" title="Netopia Payments" target="_blank" class="netopia">
                        <img src="./assets/img/resources/netopia.jpg" alt="Netopia Payments"/>
                    </a>

                    <!--<a href="https://www.librapay.ro" title="Plati online" target="_blank">-->
                    <!--    <img src="https://www.librapay.ro/images/icon_securitate_LibraPay_600x60px.jpg"-->
                    <!--         alt="Icon securitate LibraPay"/>-->
                    <!--</a>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Footer from '@/components/Footer';

export default {
    name: 'App',

    components: {
        Footer
    }
};
</script>
