<template>
    <section class="offer">
        <div class="wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xs-12 col-md-8 col-md-offset-2 title main-title">
                        <h2>Hai să lucrăm împreună!</h2>
                        <p>
                            Fie că ai o idee pentru un nou proiect sau ai deja un proiect ce necesită o împrospătare,
                            suntem alături de tine, pregătiți pentru orice provocare.
                        </p>

                        <div><router-link to="contact" class="button alternative">Solicită o Ofertă</router-link></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Contact'
};
</script>
