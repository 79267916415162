import 'lazysizes';

// Font Observer
// ----------------------------------------------------------------------------
const FontFaceObserver = require('fontfaceobserver');
const font             = new FontFaceObserver('Montserrat');

font.load().then(() => {
    document.documentElement.className += 'fonts-loaded';
});

// Fixed Nav
// ----------------------------------------------------------------------------
const setNavSticky = () => {
    const header = document.querySelector('.header') || document.querySelector('.pages-header');
    const navbar = document.querySelector('.navbar');

    if (header || navbar) {
        const sticky = navbar.offsetTop + (navbar.clientHeight / 2) - 30;

        if (window.scrollY >= sticky) {
            navbar.classList.add('fixed');
            header.classList.add('has-nav-fixed');
        } else {
            navbar.classList.remove('fixed');
            header.classList.remove('has-nav-fixed');
        }
    }
};

window.onscroll = () => setNavSticky();

// ----------------------------------------------------------------------------

// Scroll to heading
// ----------------------------------------------------------------------------
const permalinks = document.querySelectorAll('.permalink');

for (let i = 0; i < permalinks.length; i++) {
    const element = permalinks[i];

    element.addEventListener('click', ev => {
        ev.preventDefault();

        const scrollElementId = ev.target.href.split('#')[1];

        const scrollEndElement = document.getElementById(scrollElementId);

        requestAnimationFrame(timestamp => {
            const stamp    = timestamp || new Date().getTime();
            const duration = 800;
            const start    = stamp;

            const startScrollOffset = window.pageYOffset - 80;

            const scrollEndElementTop = scrollEndElement.getBoundingClientRect().top;

            scrollToElement(start, stamp, duration, scrollEndElementTop, startScrollOffset);
        });
    });
}

const easeInCubic = t => t * t * t;

const scrollToElement = (startTime, currentTime, duration, scrollEndElementTop, startScrollOffset) => {
    const runtime = currentTime - startTime;
    let progress  = runtime / duration;

    progress = Math.min(progress, 1);

    const ease = easeInCubic(progress);

    window.scroll(0, startScrollOffset + (scrollEndElementTop * ease));

    if (runtime < duration) {
        requestAnimationFrame(timestamp => {
            const currentTime = timestamp || new Date().getTime();
            scrollToElement(startTime, currentTime, duration, scrollEndElementTop, startScrollOffset);
        });
    }
};
