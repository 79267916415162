<template>
    <footer class="footer">
        <div class="wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xs-12 b-top">
                        <Logo class="my-0 footer-logo"/>
                    </div>
                </div>
            </div>

            <div class="flex">
                <div class="info">
                    <div class="company-info">
                        <div class="contact">
                            <h4>Contact</h4>
                            <p>
                                Str. Mihail Sebastian, nr. 28, Cam. 3 <br>
                                București, România
                            </p>
                            <p>+40 771 425 085</p>
                            <p>
                                <a href="mailto:contact@crobert.ro">contact@crobert.ro</a> <br>
                            </p>
                        </div>

                        <ul class="social">
                            <li>
                                <a href="https://www.facebook.com/RobertCristianChiribuc" target="_blank">
                                    <svg
                                        id="facebook"
                                        data-name="facebook"
                                        height="18"
                                        width="9.64"
                                        viewBox="0 0 9.64 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.31,10.25,9.81,7H6.69V4.87A1.62,1.62,0,0,1,8.52,3.11H9.94V.34A17.15,17.15,0,0,0,7.42.12C4.85.12,3.17,1.68,3.17,4.51V7H.3v3.26H3.17v7.87H6.69V10.25Z"
                                            transform="translate(-0.3 -0.12)"
                                        />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/robertchiribuc" target="_blank">
                                    <svg
                                        id="twitter"
                                        data-name="twitter"
                                        height="22.16"
                                        width="18"
                                        viewBox="0 0 22.16 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M19.88,4.61a5.72,5.72,0,0,1,0,.59A12.83,12.83,0,0,1,7,18.12a12.78,12.78,0,0,1-7-2,10,10,0,0,0,1.1.06A9.15,9.15,0,0,0,6.74,14.2a4.56,4.56,0,0,1-4.25-3.15,6,6,0,0,0,.86.07A4.74,4.74,0,0,0,4.54,11,4.54,4.54,0,0,1,.9,6.5v0A4.56,4.56,0,0,0,3,7a4.55,4.55,0,0,1-2-3.79A4.46,4.46,0,0,1,1.55,1,12.85,12.85,0,0,0,10.91,5.7a5.1,5.1,0,0,1-.11-1,4.55,4.55,0,0,1,7.86-3.11A8.72,8.72,0,0,0,21.54.46a4.5,4.5,0,0,1-2,2.5,9.15,9.15,0,0,0,2.61-.7,9.78,9.78,0,0,1-2.28,2.35Z"
                                            transform="translate(0 -0.12)"
                                        />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://github.com/chiribuc" target="_blank">
                                    <svg
                                        id="github"
                                        data-name="github"
                                        height="18"
                                        width="19"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6.44,14.61c0,.08-.08.14-.19.14S6,14.7,6,14.61s.09-.13.2-.13S6.44,14.53,6.44,14.61Zm-1.15-.16c0,.07,0,.16.16.18a.17.17,0,0,0,.23-.08c0-.07,0-.16-.16-.19a.18.18,0,0,0-.23.09Zm1.64-.07c-.11,0-.18.1-.17.19s.11.12.22.09.18-.09.17-.17S7,14.37,6.93,14.38ZM9.38.12A8.93,8.93,0,0,0,.27,9.2a9.32,9.32,0,0,0,6.31,8.9c.47.09.64-.2.64-.45s0-1.5,0-2.28c0,0-2.6.56-3.15-1.11,0,0-.43-1.08-1-1.36,0,0-.85-.59.06-.58a2,2,0,0,1,1.44,1,2,2,0,0,0,2.71.78,2.07,2.07,0,0,1,.6-1.25c-2.08-.23-4.18-.53-4.18-4.11a2.82,2.82,0,0,1,.88-2.2A3.46,3.46,0,0,1,4.62,4c.78-.25,2.57,1,2.57,1a8.72,8.72,0,0,1,4.68,0s1.79-1.25,2.57-1a3.46,3.46,0,0,1,.09,2.52,2.9,2.9,0,0,1,1,2.2c0,3.59-2.19,3.87-4.27,4.11a2.2,2.2,0,0,1,.63,1.73c0,1.25,0,2.8,0,3.11s.17.53.65.45a9.25,9.25,0,0,0,6.24-8.9A9.13,9.13,0,0,0,9.38.12ZM3.89,13c-.05,0,0,.12,0,.19s.15.09.2,0,0-.12,0-.2S3.94,12.91,3.89,13Zm-.4-.3s0,.1.08.14a.1.1,0,0,0,.16,0s0-.1-.08-.14S3.51,12.62,3.49,12.66ZM4.69,14c-.06,0,0,.16,0,.23s.19.1.24,0,0-.16-.05-.23S4.74,13.92,4.69,14Zm-.42-.55a.16.16,0,0,0,0,.22c.06.09.16.13.21.09a.19.19,0,0,0,0-.23c-.06-.09-.15-.12-.21-.08Z"
                                            transform="translate(-0.27 -0.12)"
                                        />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/robertchiribuc" target="_blank">
                                    <svg
                                        id="instagram"
                                        data-name="instagram"
                                        height="18"
                                        width="18"
                                        viewBox="0 0 18 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.12,4.51a4.61,4.61,0,1,0,4.62,4.61A4.6,4.6,0,0,0,9.12,4.51Zm0,7.61a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM15,4.32a1.08,1.08,0,1,1-1.07-1.08A1.07,1.07,0,0,1,15,4.32Zm3.06,1.09A5.32,5.32,0,0,0,16.6,1.64,5.35,5.35,0,0,0,12.83.18C11.35.1,6.89.1,5.41.18A5.35,5.35,0,0,0,1.64,1.63,5.34,5.34,0,0,0,.18,5.4C.1,6.89.1,11.35.18,12.83A5.32,5.32,0,0,0,1.64,16.6a5.35,5.35,0,0,0,3.77,1.46c1.48.08,5.94.08,7.42,0A5.3,5.3,0,0,0,16.6,16.6a5.32,5.32,0,0,0,1.46-3.77c.08-1.48.08-5.94,0-7.42Zm-1.92,9a3.06,3.06,0,0,1-1.71,1.71c-1.19.47-4,.36-5.31.36s-4.12.1-5.3-.36a3,3,0,0,1-1.71-1.71c-.47-1.19-.37-4-.37-5.31S1.64,5,2.11,3.81A3,3,0,0,1,3.82,2.1c1.18-.47,4-.36,5.3-.36s4.13-.1,5.31.36a3.06,3.06,0,0,1,1.71,1.71c.47,1.19.36,4,.36,5.31S16.61,13.24,16.14,14.43Z"
                                            transform="translate(-0.12 -0.12)"
                                        />
                                    </svg>
                                </a>
                            </li>
                        </ul>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="70"
                            width="90"
                            viewBox="0 0 81.68 36"
                        >
                            <title>Conform GDPR</title>
                            <g fill="#E9D8FD">
                                <path
                                    id="Shape"
                                    d="M24.42,17.84h-.55v-1.7a3.49,3.49,0,1,0-7,0v1.7h-.55A1.13,1.13,0,0,0,15.24,19v4.56a1.12,1.12,0,0,0,1.1,1.14h8.08a1.12,1.12,0,0,0,1.1-1.14V19A1.13,1.13,0,0,0,24.42,17.84Zm-2.39,0h-3.3v-1.7a1.65,1.65,0,1,1,3.3,0Z"
                                    transform="translate(-2.38 -0.96)"
                                />
                                <g id="Group">
                                    <polygon
                                        points="18 0 18.58 1.89 20.47 1.89 18.94 3.05 19.53 4.94 18 3.77 16.47 4.94 17.06 3.05 15.53 1.89 17.42 1.89 18 0"
                                    />
                                    <polygon
                                        points="18 31.06 18.58 32.95 20.47 32.95 18.94 34.11 19.53 36 18 34.83 16.47 36 17.06 34.11 15.53 32.95 17.42 32.95 18 31.06"
                                    />
                                    <polygon
                                        points="25.77 2.82 26.35 4.71 28.23 4.71 26.71 5.88 27.29 7.76 25.77 6.6 24.24 7.76 24.82 5.88 23.29 4.71 25.18 4.71 25.77 2.82"
                                    />
                                    <polygon
                                        points="32.04 23.14 32.63 25.03 34.51 25.03 32.99 26.2 33.57 28.08 32.04 26.92 30.52 28.08 31.1 26.2 29.57 25.03 31.46 25.03 32.04 23.14"
                                    />
                                    <polygon
                                        points="33.53 15.53 34.11 17.42 36 17.42 34.47 18.58 35.06 20.47 33.53 19.3 32 20.47 32.59 18.58 31.06 17.42 32.95 17.42 33.53 15.53"
                                    />
                                    <polygon
                                        points="2.47 15.53 3.05 17.42 4.94 17.42 3.41 18.58 4 20.47 2.47 19.3 0.94 20.47 1.53 18.58 0 17.42 1.89 17.42 2.47 15.53"
                                    />
                                    <polygon
                                        points="3.76 23.14 4.34 25.03 6.23 25.03 4.7 26.2 5.28 28.08 3.76 26.92 2.23 28.08 2.81 26.2 1.29 25.03 3.17 25.03 3.76 23.14"
                                    />
                                    <polygon
                                        points="32.04 7.71 32.63 9.6 34.51 9.6 32.99 10.77 33.57 12.65 32.04 11.49 30.52 12.65 31.1 10.77 29.57 9.6 31.46 9.6 32.04 7.71"
                                    />
                                    <polygon
                                        points="4.4 7.71 4.98 9.6 6.87 9.6 5.34 10.77 5.93 12.65 4.4 11.49 2.87 12.65 3.46 10.77 1.93 9.6 3.82 9.6 4.4 7.71"
                                    />
                                    <polygon
                                        points="27.18 29.65 27.76 31.53 29.65 31.53 28.12 32.7 28.7 34.59 27.18 33.42 25.65 34.59 26.23 32.7 24.71 31.53 26.59 31.53 27.18 29.65"
                                    />
                                    <polygon
                                        points="10.23 2.82 10.82 4.71 12.71 4.71 11.18 5.88 11.76 7.76 10.23 6.6 8.71 7.76 9.29 5.88 7.76 4.71 9.65 4.71 10.23 2.82"
                                    />
                                    <polygon
                                        points="8.82 29.65 9.41 31.54 11.29 31.54 9.77 32.7 10.35 34.59 8.82 33.42 7.3 34.59 7.88 32.7 6.35 31.54 8.24 31.54 8.82 29.65"
                                    />
                                </g>
                                <path
                                    id="GDPR"
                                    d="M44,25.64a3.46,3.46,0,0,0,3.67,3.5,3.93,3.93,0,0,0,3-1.32V25.17H47.4v1.45H49v.57a2.12,2.12,0,0,1-1.28.4,2,2,0,0,1,0-3.91,1.7,1.7,0,0,1,1.44.8l1.46-.77a3.19,3.19,0,0,0-2.9-1.57A3.45,3.45,0,0,0,44,25.64ZM51.7,29V22.25h2.85c2.13,0,3.67,1.28,3.67,3.38A3.33,3.33,0,0,1,54.56,29Zm1.75-1.52h1.1a1.79,1.79,0,0,0,1.88-1.86,1.73,1.73,0,0,0-1.87-1.86H53.45ZM59.09,29V22.25h3.4a2.24,2.24,0,1,1,0,4.47H60.84V29Zm4-4.52a.76.76,0,0,0-.82-.77H60.84v1.52h1.41A.75.75,0,0,0,63.07,24.49ZM69.41,29l-1.14-2.29h-.89V29H65.63V22.25H69a2.18,2.18,0,0,1,2.36,2.25,2.05,2.05,0,0,1-1.36,2L71.42,29Zm.2-4.53a.78.78,0,0,0-.85-.76H67.38v1.52h1.38A.78.78,0,0,0,69.61,24.48Z"
                                    transform="translate(-2.38 -0.96)"
                                />
                                <g id="CONFORM">
                                    <g class="cls-3">
                                        <path
                                            d="M48,12.21a1.22,1.22,0,0,0-.26-.3,1.25,1.25,0,0,0-.36-.2,1.41,1.41,0,0,0-.41-.07,1.32,1.32,0,0,0-.68.16,1.36,1.36,0,0,0-.45.41,1.84,1.84,0,0,0-.25.59,3.08,3.08,0,0,0-.08.68,2.91,2.91,0,0,0,.08.66,1.83,1.83,0,0,0,.25.57,1.36,1.36,0,0,0,.45.41,1.42,1.42,0,0,0,.68.15,1.08,1.08,0,0,0,.84-.33,1.51,1.51,0,0,0,.37-.87h1.14A2.59,2.59,0,0,1,49,15a2.16,2.16,0,0,1-.49.69,2.22,2.22,0,0,1-.72.44,2.67,2.67,0,0,1-.91.15A2.82,2.82,0,0,1,45.81,16a2.37,2.37,0,0,1-.83-.59,2.64,2.64,0,0,1-.51-.88,3.24,3.24,0,0,1-.18-1.09,3.31,3.31,0,0,1,.18-1.11,2.74,2.74,0,0,1,.51-.9,2.53,2.53,0,0,1,.83-.6,2.82,2.82,0,0,1,1.94-.09,2.4,2.4,0,0,1,.71.37,1.89,1.89,0,0,1,.51.61,2.14,2.14,0,0,1,.26.82H48.09A1,1,0,0,0,48,12.21Z"
                                            transform="translate(-2.38 -0.96)"
                                        />
                                        <path
                                            d="M50.21,12.37a2.59,2.59,0,0,1,.52-.9,2.43,2.43,0,0,1,.83-.6,2.89,2.89,0,0,1,2.21,0,2.53,2.53,0,0,1,.83.6,2.76,2.76,0,0,1,.52.9,3.31,3.31,0,0,1,.18,1.11,3.24,3.24,0,0,1-.18,1.09,2.66,2.66,0,0,1-.52.88,2.37,2.37,0,0,1-.83.59,2.89,2.89,0,0,1-2.21,0,2.28,2.28,0,0,1-.83-.59,2.5,2.5,0,0,1-.52-.88A3.24,3.24,0,0,1,50,13.48,3.31,3.31,0,0,1,50.21,12.37Zm1.08,1.77a1.63,1.63,0,0,0,.25.57,1.19,1.19,0,0,0,.45.41,1.38,1.38,0,0,0,.67.15,1.42,1.42,0,0,0,.68-.15,1.36,1.36,0,0,0,.45-.41,1.83,1.83,0,0,0,.25-.57,2.91,2.91,0,0,0,.08-.66A3.08,3.08,0,0,0,54,12.8a1.84,1.84,0,0,0-.25-.59,1.36,1.36,0,0,0-.45-.41,1.32,1.32,0,0,0-.68-.16,1.28,1.28,0,0,0-.67.16,1.19,1.19,0,0,0-.45.41,1.65,1.65,0,0,0-.25.59,2.58,2.58,0,0,0-.08.68A2.44,2.44,0,0,0,51.29,14.14Z"
                                            transform="translate(-2.38 -0.96)"
                                        />
                                        <path
                                            d="M57.46,10.78l2.23,3.59h0V10.78h1.1v5.36H59.63L57.4,12.55h0v3.59h-1.1V10.78Z"
                                            transform="translate(-2.38 -0.96)"
                                        />
                                        <path
                                            d="M65.8,10.78v1H63.21V13h2.24v.91H63.21v2.22H62V10.78Z"
                                            transform="translate(-2.38 -0.96)"
                                        />
                                        <path
                                            d="M66.61,12.37a2.59,2.59,0,0,1,.52-.9,2.43,2.43,0,0,1,.83-.6,2.77,2.77,0,0,1,1.1-.22,2.83,2.83,0,0,1,1.12.22,2.49,2.49,0,0,1,1.34,1.5,3.31,3.31,0,0,1,.18,1.11,3.24,3.24,0,0,1-.18,1.09,2.5,2.5,0,0,1-.52.88,2.42,2.42,0,0,1-.82.59,2.83,2.83,0,0,1-1.12.22A2.77,2.77,0,0,1,68,16a2.28,2.28,0,0,1-.83-.59,2.5,2.5,0,0,1-.52-.88,3.24,3.24,0,0,1-.18-1.09A3.31,3.31,0,0,1,66.61,12.37Zm1.08,1.77a1.63,1.63,0,0,0,.25.57,1.36,1.36,0,0,0,.45.41,1.38,1.38,0,0,0,.67.15,1.4,1.4,0,0,0,.68-.15,1.27,1.27,0,0,0,.45-.41,1.63,1.63,0,0,0,.25-.57,2.91,2.91,0,0,0,.08-.66,3.08,3.08,0,0,0-.08-.68,1.65,1.65,0,0,0-.25-.59,1.27,1.27,0,0,0-.45-.41,1.29,1.29,0,0,0-.68-.16,1.28,1.28,0,0,0-.67.16,1.36,1.36,0,0,0-.45.41,1.65,1.65,0,0,0-.25.59,3.08,3.08,0,0,0-.08.68A2.91,2.91,0,0,0,67.69,14.14Z"
                                            transform="translate(-2.38 -0.96)"
                                        />
                                        <path
                                            d="M75.57,10.78a1.67,1.67,0,0,1,.65.12,1.42,1.42,0,0,1,.81.78,1.4,1.4,0,0,1,.11.58,1.56,1.56,0,0,1-.2.82,1.21,1.21,0,0,1-.64.52v0a.89.89,0,0,1,.35.18,1,1,0,0,1,.24.29,1.47,1.47,0,0,1,.13.37,2.87,2.87,0,0,1,0,.41,2.36,2.36,0,0,1,0,.3c0,.11,0,.23,0,.35a1.86,1.86,0,0,0,.06.34.65.65,0,0,0,.12.28H76.12a2.53,2.53,0,0,1-.12-.61,4.89,4.89,0,0,0-.07-.68,1,1,0,0,0-.25-.61A.88.88,0,0,0,75,14H73.86v2.1H72.69V10.78Zm-.42,2.42a.87.87,0,0,0,.61-.18.72.72,0,0,0,.21-.58.71.71,0,0,0-.21-.57.87.87,0,0,0-.61-.17H73.86v1.5Z"
                                            transform="translate(-2.38 -0.96)"
                                        />
                                        <path
                                            d="M80,10.78l1.25,3.68h0l1.18-3.68h1.66v5.36H83v-3.8h0l-1.31,3.8h-.91l-1.31-3.76h0v3.76h-1.1V10.78Z"
                                            transform="translate(-2.38 -0.96)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>

                    <div class="info-links">
                        <h4>Link-uri utile</h4>
                        <ul class="nav">
                            <li>
                                <router-link to="/">Acasă</router-link>
                            </li>
                            <li>
                                <router-link to="dezvoltare-software">Dezvoltare Software</router-link>
                            </li>
                            <li>
                                <router-link to="design-grafic">Design Grafic</router-link>
                            </li>
                            <li>
                                <router-link to="confidentialitate">Confidențialitate</router-link>
                            </li>
                            <li>
                                <router-link to="termeni">Termeni</router-link>
                            </li>
                            <li>
                                <a href="https://anpc.ro/">ANPC/SAL</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="card newsletter">
                    <div class="card-content">
                        <div class="card-title">
                            Abonează-te la Newsletter
                        </div>

                        <div class="card-subtitle">
                            Sfaturi, trucuri rapide și tutoriale interensante.
                        </div>

                        <div class="card-text">
                            <form action="#" method="POST" class="dark">
                                <div class="form-group">
                                    <input type="email" name="email" class="form-control" placeholder="E-mail">
                                </div>

                                <button type="submit" class="button alternative small mt-2">Abonare</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="copyright">
                            <p><span>© {{ new Date().getFullYear() }}</span> Robert-Cristian Chiribuc PFA</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import Logo from '@/components/Logo';

export default {
    name: 'Footer',

    components: {
        Logo
    }
};
</script>
