<template>
    <h1 class="logo">C • ROBERT</h1>
    <!--<svg-->
    <!--    id="app-logo"-->
    <!--    class="app-logo"-->
    <!--    width="70"-->
    <!--    height="64"-->
    <!--    viewBox="0 0 70 64"-->
    <!--    xmlns="http://www.w3.org/2000/svg"-->
    <!--&gt;-->
    <!--    <path-->
    <!--        d="M31.76,22.82H26.33v6.67a10.77,10.77,0,0,0,.08,1.5,3,3,0,0,0,.37,1.17,1.91,1.91,0,0,0,.89.77,4,4,0,0,0,1.58.26,11.2,11.2,0,0,0,1.29-.1,2.21,2.21,0,0,0,1.22-.48v5.15a9.23,9.23,0,0,1-2.14.49,17.83,17.83,0,0,1-2.18.13,11.71,11.71,0,0,1-2.89-.33,6.63,6.63,0,0,1-2.31-1,4.94,4.94,0,0,1-1.54-1.88,6.36,6.36,0,0,1-.56-2.79V22.82H16.2V17.87h3.95V11.94h6.18v5.93h5.43Z"-->
    <!--        transform="translate(-9.34 -8.24)"-->
    <!--    />-->
    <!--    <path-->
    <!--        d="M40.92,8.24v13.4H41a3.26,3.26,0,0,1,.7-1.12,5.45,5.45,0,0,1,1.19-1,6.94,6.94,0,0,1,1.65-.72,7.53,7.53,0,0,1,2.06-.28,8.79,8.79,0,0,1,3.78.7,5.48,5.48,0,0,1,2.25,1.94,7.67,7.67,0,0,1,1.09,2.91A22,22,0,0,1,54,27.7V38.38H47.84V28.89c0-.56,0-1.13-.06-1.73a5.55,5.55,0,0,0-.37-1.66,2.89,2.89,0,0,0-.95-1.23,3,3,0,0,0-1.84-.48,3.89,3.89,0,0,0-1.93.42,2.81,2.81,0,0,0-1.13,1.11A4.46,4.46,0,0,0,41,26.9a14.06,14.06,0,0,0-.12,1.83v9.65H34.75V8.24Z"-->
    <!--        transform="translate(-9.34 -8.24)"-->
    <!--    />-->
    <!--    <path-->
    <!--        d="M77.47,34.43a9.85,9.85,0,0,1-3.75,2.92,11.17,11.17,0,0,1-4.69,1,12.59,12.59,0,0,1-4.35-.74,10.48,10.48,0,0,1-3.54-2.12,9.94,9.94,0,0,1-2.36-3.31,10.47,10.47,0,0,1-.87-4.33,10.42,10.42,0,0,1,.87-4.32,9.88,9.88,0,0,1,2.36-3.32,10.64,10.64,0,0,1,3.54-2.12A12.59,12.59,0,0,1,69,17.38a10,10,0,0,1,3.89.74,8.11,8.11,0,0,1,3,2.12,9.69,9.69,0,0,1,1.87,3.32,13.32,13.32,0,0,1,.66,4.32v1.94H64.08a4.93,4.93,0,0,0,1.61,2.82,4.54,4.54,0,0,0,3,1A4.66,4.66,0,0,0,71.31,33a6.78,6.78,0,0,0,1.83-1.75Zm-5.23-8.81a3.59,3.59,0,0,0-1-2.68,3.65,3.65,0,0,0-2.76-1.11,4.6,4.6,0,0,0-1.81.33A4.45,4.45,0,0,0,65.3,23a3.7,3.7,0,0,0-.87,1.2,4,4,0,0,0-.35,1.42Z"-->
    <!--        transform="translate(-9.34 -8.24)"-->
    <!--    />-->
    <!--    <path-->
    <!--        d="M9.34,29.55h6.18V42.81h.08a5.8,5.8,0,0,1,2.74-2,10.55,10.55,0,0,1,3.6-.6,8.1,8.1,0,0,1,3.83.89,8.84,8.84,0,0,1,2.87,2.36,11.25,11.25,0,0,1,1.81,3.4,12.7,12.7,0,0,1,.64,4,12.06,12.06,0,0,1-.7,4.16,9.49,9.49,0,0,1-2,3.27,9,9,0,0,1-3.11,2.15,10.31,10.31,0,0,1-4,.76,6.83,6.83,0,0,1-2-.28,9,9,0,0,1-1.75-.75,7,7,0,0,1-1.4-1,6.7,6.7,0,0,1-1-1.09H15v2.64H9.34ZM15,50.67a5.22,5.22,0,0,0,1.29,3.67A4.75,4.75,0,0,0,20,55.74a4.72,4.72,0,0,0,3.64-1.4,5.83,5.83,0,0,0,0-7.34A4.72,4.72,0,0,0,20,45.6,4.75,4.75,0,0,0,16.32,47,5.17,5.17,0,0,0,15,50.67Z"-->
    <!--        transform="translate(-9.34 -8.24)"-->
    <!--    />-->
    <!--    <path-->
    <!--        d="M54.13,60.67H48.2V57.94h-.08a7.34,7.34,0,0,1-.85,1.16,5.46,5.46,0,0,1-1.26,1,7.44,7.44,0,0,1-1.68.75,6.88,6.88,0,0,1-2.06.29,8.63,8.63,0,0,1-3.81-.72,5.56,5.56,0,0,1-2.27-2,8.07,8.07,0,0,1-1.07-3,25.06,25.06,0,0,1-.26-3.77V40.52H41v9.87q0,.87.06,1.8a5.77,5.77,0,0,0,.38,1.73,2.65,2.65,0,0,0,2.78,1.78,3.78,3.78,0,0,0,1.93-.44,2.87,2.87,0,0,0,1.13-1.16,4.7,4.7,0,0,0,.52-1.64,15.15,15.15,0,0,0,.12-1.9v-10h6.18Z"-->
    <!--        transform="translate(-9.34 -8.24)"-->
    <!--    />-->
    <!--    <path-->
    <!--        d="M76.69,44.67A1.72,1.72,0,1,1,74.93,43,1.73,1.73,0,0,1,76.69,44.67Z"-->
    <!--        transform="translate(-9.34 -8.24)"-->
    <!--    />-->
    <!--    <path-->
    <!--        d="M76.62,57.69a10.8,10.8,0,0,0,2.72-7.21,11,11,0,0,0-.59-3.6,4.31,4.31,0,0,1-5.17,1.93,5.41,5.41,0,0,1,.27,1.74,4.9,4.9,0,0,1-4.94,4.94,5,5,0,0,1-4.79-5.16,5.07,5.07,0,0,1,5-5,4.93,4.93,0,0,1,1.61.34,3.83,3.83,0,0,1-.13-.9,4.42,4.42,0,0,1,2.7-4.11,10,10,0,0,0-4.18-.94A10.39,10.39,0,0,0,58.72,50.41a10.42,10.42,0,0,0,10.2,10.72,10.22,10.22,0,0,0,4-.78,4.22,4.22,0,0,1-1.09,5.89A4.33,4.33,0,0,1,66,65.09l-3.83,2.67A8.9,8.9,0,1,0,76.75,57.6Z"-->
    <!--        transform="translate(-9.34 -8.24)"-->
    <!--    />-->
    <!--</svg>-->
</template>

<script>
export default {
    name: 'Logo'
};
</script>
