<template>
    <header class="header">
        <div class="navigation">
            <nav class="navbar">
                <div class="nav-section">
                    <div class="container">
                        <a class="navbar-brand" href="/">
                            <Logo/>
                        </a>
                        <button class="navbar-toggler" type="button" @click="toggleNav">
                            <div :class="navBurger ? 'nav-burger show' : 'nav-burger'">
                                <svg
                                    id="bars"
                                    data-name="bars"
                                    height="42"
                                    width="36"
                                    viewBox="0 0 42 36"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        style="opacity: 0.4; isolation: isolate;"
                                        d="M2,21.5H41A1.5,1.5,0,0,0,42.5,20V17A1.5,1.5,0,0,0,41,15.5H2A1.5,1.5,0,0,0,.5,17v3A1.5,1.5,0,0,0,2,21.5Z"
                                        transform="translate(-0.5 -0.5)"
                                    />
                                    <path
                                        d="M41,30.5H2A1.5,1.5,0,0,0,.5,32v3A1.5,1.5,0,0,0,2,36.5H41A1.5,1.5,0,0,0,42.5,35V32A1.5,1.5,0,0,0,41,30.5Zm0-30H2A1.5,1.5,0,0,0,.5,2V5A1.5,1.5,0,0,0,2,6.5H41A1.5,1.5,0,0,0,42.5,5V2A1.5,1.5,0,0,0,41,.5Z"
                                        transform="translate(-0.5 -0.5)"
                                    />
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
            </nav>

            <ul :class="isNavOpen ? 'nav show' : 'nav'">
                <li class="nav-close" @click="toggleNav">
                    <svg id="close" data-name="close" height="32" width="32" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 32 32">
                        <path style="opacity:0.4;isolation:isolate;"
                              d="M1.34,25.6a2.85,2.85,0,0,0,0,4l2,2a2.85,2.85,0,0,0,4,0l9.1-9.09L10.43,16.5ZM31.66,3.36l-2-2a2.85,2.85,0,0,0-4,0l-9.1,9.09,6.07,6.07,9.09-9.1A2.85,2.85,0,0,0,31.66,3.36Z"
                              transform="translate(-0.5 -0.5)"/>
                        <path
                            d="M31.66,25.6a2.85,2.85,0,0,1,0,4l-2,2a2.85,2.85,0,0,1-4,0L1.34,7.4a2.85,2.85,0,0,1,0-4l2-2a2.85,2.85,0,0,1,4,0Z"
                            transform="translate(-0.5 -0.5)"/>
                    </svg>
                </li>

                <li class="nav-item">
                    <a class="nav-link" @click="navigate('/')">Acasă</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="navigate('dezvoltare-software')">Dezvoltare Software</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="navigate('design-grafic')">Design Grafic</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="navigate('contact')">Contact</a>
                </li>
            </ul>
        </div>

        <div class="wrapper">
            <slot></slot>
        </div>
    </header>
</template>

<script>
import Logo from '@/components/Logo.vue';

export default {
    name: 'Header',

    components: {
        Logo
    },

    data () {
        return {
            isNavOpen: false,
            navBurger: false
        };
    },

    methods: {
        toggleNav () {
            this.isNavOpen = !this.isNavOpen;
            this.navBurger = !this.navBurger;
        },

        navigate (url) {
            this.toggleNav();
            this.$router.push(url);
        }
    }
};
</script>

